import React, { useState, useEffect, useRef } from "react";
import { TablePagination } from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input } from 'antd';
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../function/sizeAdapter"
import { IconButton } from "@mui/material";
import { ClearRounded, Close, SearchOutlined } from "@mui/icons-material";
import Magnifier_icon_blue from "../icon/Magnifier_icon_blue";
import Common_pagenation from '../common/Common_pagination';
import { ApiBukenBusinessSystemCdList } from '../../network/api'




const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    retrievalBlock: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#F5F5F5',
        marginTop: VH(12),
        paddingLeft: VW(16),
        paddingRight: VW(16),
        height: VH(72),
        justifyContent: 'space-between',
        '& > Input': {
            width: VW(832),
            height: VH(40),
            fontSize: VW(16)
        },
        '& > Button': {
            width: VW(112),
            height: VH(40),
            fontSize: VW(16),
            marginLeft: VW(16),
            backgroundColor: theme.palette.primary.main,
        }
    },
    dataListTitle: {
        width: '100%',
        height: VH(40),
        backgroundColor: "#F8F8F8",
        borderTop: '1px solid #DDD',
        borderBottom: '1px solid #DDD',
        display: 'flex',
        fontSize: VW(16),
        fontWeight: "bold",
        '& > div': {
            height: "100%",
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16)
        },
        '& > div:nth-child(1)': {
            width: '20%'
        },
        "& > div:nth-child(2)": {
            width: "80%"
        }
    },
    scrollBlock: {
        maxHeight: VH(320),
        width: '100%',
        overflowY: 'scroll'
    },
    dataListItem: {
        width: '100%',
        height: VH(40),
        borderBottom: '1px solid #DDD',
        display: 'flex',
        fontSize: VW(16),
        cursor: 'pointer',
        '& > div': {
            height: "100%",
            display: 'flex',
            alignItems: 'center',
            paddingLeft: VW(16)
        },
        '& > div:nth-child(1)': {
            width: '20%'
        },
        "& > div:nth-child(2)": {
            width: "80%"
        }
    }
});

function Property_select_code(props) {
    const {
        classes,
        open,
        onClose,
        buken_id,
        setMansion_buken_name,
        setMansion_buken_code
    } = props

    useEffect(() => {
        if (!open) {
            getList()
        }
    }, [open]);


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState("")


    const [keywordSearch, setKeywordSearch] = useState('')

    const getList = () => {
        let params = {
            buken_id: buken_id,
            system_key: encodeURIComponent(keywordSearch),
            pageNum: page,
            pageCount: rowsPerPage,
        }
        ApiBukenBusinessSystemCdList(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                setRrrorOpen(false)
                setCount(resData.count)
                setDataBody(resData.data)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }



    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  paging
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        setCurrentPage(newPage)
    };
    const handleChangePageForPagination = (event, newPage) => {
        const page = newPage - 1
        setPage(page)
        setCurrentPage(page)
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    // get list
    useEffect(() => {
        getList()
    }, [page, rowsPerPage])

    const [dataBody, setDataBody] = useState([])

    // click item
    const selectItem = (item) => {
        if (!item.isSelected) {
            setMansion_buken_name(item.buken_name)
            setMansion_buken_code(item.buken_id)
            onClose()
        }
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1112)}
            closable={false}
        >
            <div>
                <div className={classes.tableTitle}>
                    <Magnifier_icon_blue size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>業務管理システム物件CDの検索</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.retrievalBlock}>
                    <Input
                        placeholder='キーワードを入力してください'
                        value={keywordSearch}
                        style={{
                            height: VH(40),
                            fontSize: VW(16)
                        }}
                        onChange={(event) => { setKeywordSearch(event.target.value) }}
                        suffix={
                            (
                                <ClearRounded className={classes.iconContainer} style={{ display: keywordSearch ? '' : 'none' }} onClick={(e) => {
                                    setKeywordSearch('')
                                }}></ClearRounded>
                            )
                        }
                    />
                    <Button type="primary" icon={<SearchOutlined />} style={{ fontWeight: 'bold' }} onClick={getList}>検索</Button>
                </div>
                <TablePagination
                    style={{ fontSize: VW(12) }}
                    component="div"
                    count={count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[20, 50, 100]}
                    labelRowsPerPage='表示件数:'
                    className={classes.selectLabel}
                    classes={{
                        toolbar: classes.toolbar
                    }}
                />
                <div className={classes.dataListTitle}>
                    <div>業務管理システム物件CD</div>
                    <div>物件名</div>
                </div>
                <div className={classes.scrollBlock}>
                    {dataBody.length > 0 && dataBody.map((item, index) => {
                        return (
                            <div className={classes.dataListItem} key={index} onClick={() => { selectItem(item) }} style={{ color: item.isSelected ? '#DDD' : '' }}>
                                <div>{item.buken_id}</div>
                                <div>{item.buken_name}</div>
                            </div>
                        )
                    })}
                </div>
                <Common_pagenation
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onChange={handleChangePageForPagination}
                />
            </div>
        </Modal >
    )
}

Property_select_code.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Property_select_code);