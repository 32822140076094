import { VW, VH } from "../function/sizeAdapter"
function WrongIcon_gray() {
    return (
        <svg width={VW(24)} height={VH(24)} viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="12" fill="white" fillOpacity="0.6" />
            <path d="M16.6663 8.27301L15.7263 7.33301L11.9997 11.0597L8.27301 7.33301L7.33301 8.27301L11.0597 11.9997L7.33301 15.7263L8.27301 16.6663L11.9997 12.9397L15.7263 16.6663L16.6663 15.7263L12.9397 11.9997L16.6663 8.27301Z" fill="#5B5B5B" />
        </svg>
    )
}

export default WrongIcon_gray;


