export async function uploadFile(url, file, contend_type) {
  const request = {
    method: "Put",
    headers: {
      "Content-Type": contend_type,
    },
    body: file,
  };
  try {
    const response = await fetch(url, request);

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return await response;
  } catch (error) {
    console.error('File upload failed:', error);
    throw error;
  }
}

