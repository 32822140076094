
import request from './request.js';

export const ApiCustomerList = (data) => request.get('/customer/list', { data })

export const ApiToken = (data) => request.post('/token', { data })
export const ApiTokenRefresh = (data) => request.post('/token/refresh', { data })
export const ApiTokenForgotPassword = (data) => request.post('/token/forgot_password', { data })
export const ApiTokenConfirmForgotPassword = (data) => request.post('/token/confirm_forgot_password', { data })
export const ApiTokenForceChangePassword = (data) => request.post('/token/force_change_password', { data })


export const ApiUserUserauthority = (data) => request.get('/user/userauthority', { data })
export const ApiUserUpdate = (data) => request.post('/user/update', { data })
export const ApiUserHeadsculpture = (data) => request.post('/user/headsculpture', { data })


export const ApiTenantList = (data) => request.get('/tenant/list', { data })
export const ApiTenantAdd = (data) => request.post('/tenant/add', { data })
export const ApiTenantDelete = (data) => request.post('/tenant/delete', { data })
export const ApiTenantUpdate = (data) => request.post('/tenant/update', { data })
export const ApiTenantReinvite = (data) => request.post('/tenant/reinvite', { data })


export const ApiOwnerList = (data) => request.get('/owner/list', { data })
export const ApiOwnerAdd = (data) => request.post('/owner/add', { data })
export const ApiOwnerUpdate = (data) => request.post('/owner/update', { data })
export const ApiOwnerDelete = (data) => request.post('/owner/delete', { data })
export const ApiOwnerDetail = (data) => request.get('/owner/detail', { data })
export const ApiOwnerOwnerNameByTenantid = (data) => request.get('/owner/owner_name_by_tenantid', { data })


export const ApiBukenList = (data) => request.get('/buken/list', { data })
export const ApiBukenDetail = (data) => request.get('/buken/detail', { data })
export const ApiBukenUpdate = (data) => request.post('/buken/update', { data })
export const ApiBukenUpdateKeiyaku = (data) => request.post('/buken/update/keiyaku', { data })
export const ApiBukenDelete = (data) => request.post('/buken/delete', { data })
export const ApiBukenUpdateTag = (data) => request.post('/buken/update/tag', { data })
export const ApiBukenCategorynameList = (data) => request.get('/buken/categoryname/list', { data })
export const ApiBukenAdd = (data) => request.post('/buken/add', { data })
export const ApiBukenFileAdd = (data) => request.post('/buken/file/add', { data })
export const ApiBukenDetailExit = (data) => request.get('/buken/detail/exit', { data })
export const ApiBukenFileDownload = (data) => request.get('/buken/file/download', { data })
export const ApiBukenFileDownloadModel = (data) => request.get('/buken/file/download/model', { data })
export const ApiBukenFileChoosedownload = (data) => request.get('/buken/file/choosedownload', { data })
export const ApiBukenFileDownloadBase = (data) => request.get('/buken/file/download_base', { data })
export const ApiBukenFileDownloadBaseAll = (data) => request.get('/buken/file/download_base/all', { data })
export const ApiBukenFileUploadBase = (data) => request.post('/buken/file/upload_base', { data })
export const ApiBukenGetBukenId = (data) => request.get('/buken/getBukenId', { data })
export const ApiBukenBusinessSystemCdList = (data) => request.get('/buken/businessSystemCd/list', { data })



export const ApiTagListBuken = (data) => request.get('/tag/list/buken', { data })
export const ApiTagList = (data) => request.get('/tag/list', { data })
export const ApiTagAdd = (data) => request.post('/tag/add', { data })
export const ApiTagUpdate = (data) => request.post('/tag/update', { data })
export const ApiTagDetail = (data) => request.get('/tag/detail', { data })
export const ApiTagDelete = (data) => request.post('/tag/delete', { data })
export const ApiTagDetailExit = (data) => request.get('/tag/detail/exit', { data })


export const ApiNoticeList = (data) => request.get('/notice/list', { data })
export const ApiNoticeManagerList = (data) => request.get('notice/manager/list', { data })
export const ApiNoticeAdd = (data) => request.post('/notice/add', { data })
export const ApiNoticeUpdate = (data) => request.post('/notice/update', { data })
export const ApiNoticeDetail = (data) => request.get('/notice/detail', { data })
export const ApiNoticeExit = (data) => request.get('/notice/detail/exit', { data })
export const ApiNoticeDelete = (data) => request.post('/notice/delete', { data })


export const ApiBusinessrecordAdd = (data) => request.post('/businessrecord/add', { data })
export const ApiBusinessrecordList = (data) => request.get('/businessrecord/list', { data })
export const ApiBusinessrecordDetail = (data) => request.get('/businessrecord/detail', { data })
export const ApiBusinessrecordDetailExit = (data) => request.get('/businessrecord/detail/exit', { data })
export const ApiBusinessrecordUpdate = (data) => request.post('/businessrecord/update', { data })
export const ApiBusinessrecordDelete = (data) => request.post('/businessrecord/delete', { data })


export const ApiClaimrecordAdd = (data) => request.post('/claimrecord/add', { data })
export const ApiClaimrecordList = (data) => request.get('/claimrecord/list', { data })
export const ApiClaimrecordDetail = (data) => request.get('/claimrecord/detail', { data })
export const ApiClaimrecordDetailExit = (data) => request.get('/claimrecord/detail/exit', { data })
export const ApiClaimrecordUpdate = (data) => request.post('/claimrecord/update', { data })
export const ApiClaimrecordDelete = (data) => request.post('/claimrecord/delete', { data })


export const ApiMendingAdd = (data) => request.post('/mending/add', { data })
export const ApiMendingList = (data) => request.get('/mending/list', { data })
export const ApiMendingDetail = (data) => request.get('/mending/detail', { data })
export const ApiMendingDetailExit = (data) => request.get('/mending/detail/exit', { data })
export const ApiMendingUpdate = (data) => request.post('/mending/update', { data })
export const ApiMendingDelete = (data) => request.post('/mending/delete', { data })
export const ApiMendingBusinessrecord = (data) => request.get('/mending/businessrecord', { data })
export const ApiMendingClaimrecord = (data) => request.get('/mending/claimrecord', { data })
export const ApiMendingSetsubi = (data) => request.get('/mending/setsubi', { data })


export const ApiSetsubiList = (data) => request.get('/setsubi/list', { data })
export const ApiSetsubiDetail = (data) => request.get('/setsubi/detail', { data })
export const ApiSetsubiUpdate = (data) => request.post('/setsubi/update', { data })
export const ApiSetsubiDelete = (data) => request.post('/setsubi/delete', { data })
export const ApiSetsubiCategoryList = (data) => request.get('/setsubi/category/list', { data })
export const ApiSetsubiAdd = (data) => request.post('/setsubi/add', { data })
export const ApiSetsubiInfoList = (data) => request.get('/setsubi/info/list', { data })
export const ApiSetsubiInfoRoom_topic = (data) => request.get('/setsubi/info/room_topic', { data })
export const ApiSetsubiInfoAdd = (data) => request.post('/setsubi/info/add', { data })
export const ApiSetsubiTypeList = (data) => request.get('/setsubi/type/list', { data })
export const ApiSetsubiTypeAdd = (data) => request.post('/setsubi/type/add', { data })
export const ApiSetsubiTypeUpdate = (data) => request.post('/setsubi/type/update', { data })
export const ApiSetsubiTypeDelete = (data) => request.post('/setsubi/type/delete', { data })
export const ApiSetsubiTypeAssociationList = (data) => request.get('/setsubi/type/association/list', { data })
export const ApiSetsubiTypeAssociationUpdate = (data) => request.post('/setsubi/type/association/update', { data })



export const ApiMaintenanceList = (data) => request.post('/maintenance/list', { data })
export const ApiMaintenanceAdd = (data) => request.post('/maintenance/add', { data })
export const ApiMaintenanceUpdate = (data) => request.post('/maintenance/update', { data })
export const ApiMaintenanceCopy = (data) => request.post('/maintenance/copy', { data })


export const ApiCompanyMemberList = (data) => request.get('/company_member/list', { data })
export const ApiCompanyMemberAuthority = (data) => request.get('/company_member/authority', { data })
export const ApiCompanyMemberAdd = (data) => request.post('/company_member/add', { data })
export const ApiCompanyMemberDetail = (data) => request.get('/company_member/detail', { data })
export const ApiCompanyMemberUpdate = (data) => request.post('/company_member/update', { data })
export const ApiCompanyMemberDelete = (data) => request.post('/company_member/delete', { data })
export const ApiCompanyMemberReinvite = (data) => request.post('/company_member/reinvite', { data })
export const ApiCompanyMemberExit = (data) => request.get('/company_member/exit', { data })
export const ApiCompanyMemberActive = (data) => request.post('/company_member/active', { data })


export const ApiSetubirecordAdd = (data) => request.post('/setubirecord/add', { data })
export const ApiSetubirecordList = (data) => request.get('/setubirecord/list', { data })
export const ApiSetubirecordDetail = (data) => request.get('/setubirecord/detail', { data })
export const ApiSetubirecordUpdate = (data) => request.post('/setubirecord/update', { data })
export const ApiSetubirecordDelete = (data) => request.post('/setubirecord/delete', { data })
export const ApiSetubirecordDetailExit = (data) => request.get('/setubirecord/detail/exit', { data })


export const ApiFilemanageAdd = (data) => request.post('/filemanage/add', { data })
export const ApiFilemanageCreateFolder = (data) => request.post('/filemanage/create/folder', { data })
export const ApiFilemanageSetsubi = (data) => request.get('/filemanage/setsubi', { data })
export const ApiFilemanageList = (data) => request.get('/filemanage/list', { data })
export const ApiFilemanageFolderList = (data) => request.get('/filemanage/folder/list', { data })
export const ApiFilemanageDownload = (data) => request.get('/filemanage/download', { data })
export const ApiFilemanageDelete = (data) => request.post('/filemanage/delete', { data })
export const ApiFilemanageMove = (data) => request.post('/filemanage/move', { data })
export const ApiFilemanageAddCheck = (data) => request.post('/filemanage/add/check', { data })
export const ApiFilemanageFolderUpdate = (data) => request.post('/filemanage/folder/update', { data })



export const ApiReminderList = (data) => request.get('/reminder/list', { data })
export const ApiReminderManagerList = (data) => request.get('reminder/manager/list', { data })
export const ApiReminderAdd = (data) => request.post('/reminder/add', { data })
export const ApiReminderUpdate = (data) => request.post('/reminder/update', { data })
export const ApiReminderDetail = (data) => request.get('/reminder/detail', { data })
export const ApiReminderDelete = (data) => request.post('/reminder/delete', { data })


export const ApiSagyohyoList = (data) => request.get('/sagyohyo/list', { data })
export const ApiSagyohyoDetail = (data) => request.get('/sagyohyo/detail', { data })
export const ApiSagyohyoUpdate = (data) => request.post('/sagyohyo/update', { data })
export const ApiSagyohyoReadyData = (data) => request.get('/sagyohyo/ready_data', { data })
export const ApiSagyohyoAdd = (data) => request.post('/sagyohyo/add', { data })
export const ApiSagyohyoDelete = (data) => request.post('/sagyohyo/delete', { data })


export const ApiIntegrationFileUpload = (data) => request.post('/integration/file/upload', { data })
