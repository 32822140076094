import React, { useState, useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, Select, DatePicker, Radio } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import Common_errorAlert from "../common/Common_errorAlert";
import { errorMessage, formatCheck } from "../../wordsFile/wordsFile";
import { ApiBukenCategorynameList, ApiOwnerOwnerNameByTenantid, ApiBukenAdd, ApiBukenGetBukenId } from '../../network/api'
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import OwnersList_add from "../dialog/OwnersList_add";
import SearchIcon from '@mui/icons-material/Search';
import locale from "antd/es/date-picker/locale/ja_JP";
import Item_icon_title from "../../componets/common/Item_icon_title";
import Item_secondary_title from "../../componets/common/Item_secondary_title";
import WrongIcon_gray from "../icon/WrongIcon_gray";
import Property_img_upload from "./Property_img_upload";


dayjs.extend(utc)
const { Option } = Select;
const { TextArea } = Input;


const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    groundFour: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: VH(40),
        },
        '& .MuiAutocomplete-input': {
            fontSize: VW(16),
            border: '0'
        },
        '& .css-95h4pa-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(56),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            minHeight: VH(56)
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '30%'
        },
        '& > div:nth-child(3)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(4)': {
            width: '30%'
        },
    },
    groundTwo: {
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(56),
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(56),
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '80%'
        }
    },
    TextAreaBlock: {
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(120),
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: VW(16),
            paddingLeft: VW(16),
            paddingRight: VW(16),
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            minHeight: VH(120),
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '80%'
        }
    },
    nameBlock: {
        width: '100%',
        height: VH(50),
        display: 'flex',
        alignItems: 'center',
        paddingLeft: VW(16),
        fontSize: VW(16),
        border: '1px solid #DDD',
        borderBottom: 0,
        cursor: 'pointer'
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    buttonGroupThree: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(280),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:not(:nth-child(1))': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    photoBlock: {
        width: '100%',
        fontSize: VW(16),
        display: 'flex',
        minHeight: VH(56),
        borderTop: `1px solid ${theme.palette.themeBorder.main}`,
        '& > div:nth-child(1)': {
            display: 'flex',
            backgroundColor: '#F8F8F8',
            width: '20%',
            alignItems: 'center',
            paddingLeft: VW(16),
            fontWeight: 'bold'
        },
        '& > div:nth-child(2)': {
            width: '80%',
            padding: '6px 16px'
        },
    },
    imgList: {
        width: '100%',
        maxHeight: VH(168),
        display: 'flex',
        '& > div': {
            width: VW(168),
            maxHeight: VH(168),
            position: 'relative',
            '& > div': {
                position: 'absolute',
                top: 8,
                right: 6.5,
                cursor: 'pointer'
            }
        }
    },
    updateButton: {
        width: VW(280),
        height: VH(44),
        fontSize: VW(16),
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px'
    },
});

function Property_add(props) {
    const {
        classes,
        open,
        onClose,
        getList,
        setBuken_id
    } = props

    useEffect(() => {
        if (!open) {
            setTitleData({
                owner_name: "",
                buken_shubetsu: 0,
                customer_buken_code: "",
                genba_name: "",
                postal: "",
                address: "",
                syunko_date: "",
                nobeyuka_area: "",
                floor: "",
                rooms: "",
                tyurinjyo_num: "",
                tyusyajyo_num: "",
                remarks: "",
                area: "",
                kisu: "",
                hokyo_buzai: '',
                renketu_buzai: '',
                other_buzai: ''
            })
            setImages([])
            imageBukenId.current = null
            setRrrorOpen(false)
        } else {
            getOwnerList('useEffect')
        }
    }, [open])



    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState("")

    // title data
    const [titleData, setTitleData] = useState({
        owner_name: "",
        buken_shubetsu: 0,
        customer_buken_code: "",
        genba_name: "",
        postal: "",
        address: "",
        syunko_date: "",
        nobeyuka_area: "",
        floor: "",
        rooms: "",
        tyurinjyo_num: "",
        tyusyajyo_num: "",
        remarks: "",
        area: "",
        kisu: "",
        hokyo_buzai: '',
        renketu_buzai: '',
        other_buzai: ''
    })



    //change titleData
    const changeTitleData = (value, type) => {
        if (type == 'owner_name') {
            if (!value) {
                titleData[type] = ""
                setTitleData({ ...titleData })
            } else {
                titleData[type] = value
                setTitleData({ ...titleData })
            }
        } else {
            titleData[type] = value
            setTitleData({ ...titleData })
        }
        log(value)
        log(type)
        if (type == 'buken_shubetsu') {
            getBodyList()
        }
    }
    // body data
    const [bodyList, setBodyList] = useState([])
    // change bodyData
    const changeBodyData = (value, item, type) => {
        bodyList.map(tt => {
            tt.data.map(dd => {
                if (dd.result_data.id == item.id && dd.result_data.purpose_id == item.purpose_id) {
                    dd['result_data'][type] = value
                }
            })
        })
        setBodyList([...bodyList])
    }

    // open flag
    const changeOpenFlag = (item) => {
        log(item)
        bodyList.map(bodyItem => {
            if (bodyItem.parent_category_id === item.parent_category_id) {
                bodyItem.openFlag = !bodyItem.openFlag
            }
        })
        setBodyList([...bodyList])
    }

    // body list api
    const getBodyList = () => {
        let param = {
            buken_shubetsu: titleData.buken_shubetsu
        }
        ApiBukenCategorynameList(param).then(res => {
            let resData = res.data
            if (res.status == 200) {
                setRrrorOpen(false)
                log(resData)
                setBodyList([...resData.data])
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }
    // get owner list
    const getOwnerList = (flag) => {
        let params = {
            // tenant_id: localStorage.getItem('tenant_id'),
            query_content: ""
        }
        ApiOwnerOwnerNameByTenantid(params).then(res => {
            let resData = res.data
            if (res.status == 200) {
                log(resData)
                resData.data.push({ owner_name: 'オーナー不明' })
                setOwnerList([...resData.data])
                if (flag != 'useEffect') {
                    changeTitleData(resData.data[resData.data.length - 2].owner_name, 'owner_name')
                }
                if (flag == 'useEffect') {
                    getBodyList()
                }
                if (!imageBukenId.current) {
                    ApiBukenGetBukenId().then(res => {
                        if (res.status === 200) {
                            setRrrorOpen(false)
                            log(res.data.data)
                            imageBukenId.current = res.data.data

                        } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                            log(res)
                            setRrrorOpen(true)
                            setErrorContent(res.data.detail.toString())
                        }
                    }).catch(err => {

                    })
                }
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        })
    }





    // owner list
    const [ownerList, setOwnerList] = useState([])


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  add dialog owner
    const [addOpen, setAddOpen] = useState(false)
    const addOnClose = () => {
        setAddOpen(false)
    }
    const openAddDialog = () => {
        setAddOpen(true)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  upload dialog img
    const [uploadOpen, setUploadOpen] = useState(false)
    const uploadOnClose = () => {
        setUploadOpen(false)
    }
    const openUploadDialog = () => {
        setUploadOpen(true)
    }






    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  カルテ契約終了日
    const [syunkoDate, setSyunkoDate] = useState("")
    const changeSyunkoDate = (value, dateString) => {
        setSyunkoDate(value)
        titleData.syunko_date = dateString
        setTitleData({ ...titleData })
        log(dateString)
    };

    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day')
    };

    // 将Modal组件滚动到视口顶部
    const modalRef = useRef(null);



    // add api
    const toAdd = () => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        let newBodyArray = []
        bodyList.map(tt => {
            tt.data.map(dd => {
                newBodyArray.push(dd.result_data)
            })
        })
        let errText = ''
        if (titleData.postal != "" && !formatCheck.ZipCode.test(titleData.postal)) {
            errText = errText + errorMessage.zipCodeFormatError + "\n"
        }
        if (titleData.genba_name == "") {
            errText = errText + '物件名を入力してください。\n'
        }
        if (titleData.postal == "") {
            errText = errText + '郵便番号を入力してください。\n'
        }
        if (titleData.address == "") {
            errText = errText + '所在地を入力してください。\n'
        }
        if (titleData.owner_name == "") {
            errText = errText + 'オーナーを入力してください。\n'
        }


        if (errText != '') {
            setErrorContent(errText)
            setRrrorOpen(true)
        } else {
            let param = titleData
            param.nobeyuka_area = param.nobeyuka_area ? param.nobeyuka_area : null
            param.rooms = param.rooms ? param.rooms : null
            param.tyurinjyo_num = param.tyurinjyo_num ? param.tyurinjyo_num : null
            param.tyusyajyo_num = param.tyusyajyo_num ? param.tyusyajyo_num : null
            param.result = newBodyArray
            param.buken_image_url = images.length > 0 ? images[0].image_key : ''
            param.buken_id = imageBukenId.current
            log(param)
            ApiBukenAdd(param).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    log(res.data)
                    onClose()
                    getList('add')
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }




    // equipment add
    const addEquipment = () => {
        // 将Modal组件滚动到视口顶部
        const modalNode = modalRef.current;
        modalNode.scrollIntoView({ block: "start" });
        let newBodyArray = []
        bodyList.map(tt => {
            tt.data.map(dd => {
                newBodyArray.push(dd.result_data)
            })
        })
        let errText = ''
        if (titleData.postal != "" && !formatCheck.ZipCode.test(titleData.postal)) {
            errText = errText + errorMessage.zipCodeFormatError + "\n"
        }
        if (titleData.genba_name == "") {
            errText = errText + '物件名を入力してください。\n'
        }
        if (titleData.postal == "") {
            errText = errText + '郵便番号を入力してください。\n'
        }
        if (titleData.address == "") {
            errText = errText + '所在地を入力してください。\n'
        }
        if (titleData.owner_name == "") {
            errText = errText + 'オーナーを入力してください。\n'
        }

        if (errText != '') {
            setErrorContent(errText)
            setRrrorOpen(true)
        } else {
            let param = titleData
            param.nobeyuka_area = param.nobeyuka_area ? param.nobeyuka_area : null
            param.rooms = param.rooms ? param.rooms : null
            param.tyurinjyo_num = param.tyurinjyo_num ? param.tyurinjyo_num : null
            param.tyusyajyo_num = param.tyusyajyo_num ? param.tyusyajyo_num : null
            param.result = newBodyArray
            param.buken_image_url = images.length > 0 ? images[0].image_key : ''
            param.buken_id = imageBukenId.current
            log(param)
            ApiBukenAdd(param).then(res => {
                if (res.status === 200) {
                    setRrrorOpen(false)
                    log(res.data)
                    setBuken_id(res.data.buken_id)
                    onClose()
                    getList('eqAdd')
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }
    }



    // image List
    const imgName = useRef(null)
    const imageBukenId = useRef(null)
    const [images, setImages] = useState([])
    const imgBody = useRef()
    const deleteImg = (index) => {
        images.splice(index, 1)
        setImages([...images])
    }




    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div ref={modalRef}>
                <div className={classes.tableTitle}>
                    <AddIcon size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>物件の登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.groundTwo} >
                    <div>建物・コンテナ<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                    <div>
                        <Radio.Group onChange={(e) => { changeTitleData(e.target.value, 'buken_shubetsu') }} value={titleData.buken_shubetsu}>
                            <Radio value={0} style={{ fontSize: VW(16) }} defaultChecked>建物</Radio>
                            <Radio value={1} style={{ marginLeft: VW(40), fontSize: VW(16) }}>コンテナ</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className={classes.photoBlock}>
                    <div>物件画像</div>
                    <div>
                        <div className={classes.imgList}>
                            {images.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <img
                                            src={item.s3_image_url}
                                            style={{ width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center' }}
                                        />
                                        <div onClick={() => { deleteImg(index) }}>
                                            <WrongIcon_gray />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {images.length != 1 &&
                            <Button
                                className={classes.updateButton}
                                onClick={openUploadDialog}
                            >画像アップロード
                            </Button>
                        }
                    </div>
                </div>
                <div className={classes.groundFour}>
                    <div>物件CD</div>
                    <div>
                        <Input
                            style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.customer_buken_code}
                            onChange={(e) => { changeTitleData(e.target.value, 'customer_buken_code') }}
                            maxLength={50}
                            placeholder="物件CD"
                        />
                    </div>
                    <div>エリア</div>
                    <div>
                        <Input
                            style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.area}
                            onChange={(e) => { changeTitleData(e.target.value, 'area') }}
                            maxLength={60}
                            placeholder="エリア"
                        />
                    </div>
                </div>
                <div className={classes.groundTwo} >
                    <div>物件名<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                    <div>
                        <Input
                            style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.genba_name}
                            onChange={(e) => { changeTitleData(e.target.value, 'genba_name') }}
                            maxLength={100}
                            placeholder="物件名"
                        />
                    </div>
                </div>
                <div className={classes.groundTwo}>
                    <div>郵便番号<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                    <div>
                        <div>〒</div>
                        <div>
                            <Input
                                style={{ width: VW(120), marginLeft: VW(10), marginRight: VW(16), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                placeholder="000-0000"
                                value={titleData.postal}
                                onChange={(e) => { changeTitleData(e.target.value, 'postal') }}
                                maxLength={8}
                            />
                        </div>
                        <div style={{ color: '#AAA' }}>※半角７桁ハイフンあり</div>
                    </div>
                </div>
                <div className={classes.groundTwo}>
                    <div>所在地<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                    <div>
                        <Input
                            style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            placeholder="所在地"
                            value={titleData.address}
                            onChange={(e) => { changeTitleData(e.target.value, 'address') }}
                            maxLength={100}
                        />
                    </div>
                </div>
                <div className={classes.groundFour}>
                    <div>オーナー<span style={{ color: 'red', fontWeight: 'bold', marginLeft: VW(8) }}>*</span></div>
                    <div >
                        <Autocomplete
                            disablePortal
                            options={ownerList.map((option) => option.owner_name)}
                            defaultValue={null}
                            value={titleData.owner_name ? titleData.owner_name : ''}
                            noOptionsText={"存在しないオーナーが入力されています。存在するオーナーを入力するか、オーナー選択欄にて、オーナーの新規登録を行ってから、物件情報の登録をしてください。"}
                            onChange={(e, newValue) => { changeTitleData(newValue, 'owner_name') }}
                            style={{ width: VW(264), fontSize: VW(16), borderRadius: '4px' }}
                            forcePopupIcon={false}
                            renderInput={(params) => <TextField placeholder='オーナー'{...params} label="" size="small" />}
                            PaperComponent={({ children }) => {
                                return (
                                    <div
                                        style={{
                                            backgroundColor: '#FFF',
                                            border: '1px solid #DDD',
                                        }}
                                    >
                                        {children}
                                        <div className={classes.nameBlock} style={{ border: 0, borderTop: '1px solid #DDD' }} onMouseDown={openAddDialog}>
                                            <div style={{ marginRight: VW(8) }}>新規登録</div>
                                            <div style={{ marginTop: VH(3) }}>
                                                <AddIcon color='#5B5B5B' />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                        ></Autocomplete>
                        <SearchIcon style={{ width: VW(24), height: VH(24), marginLeft: VW(8) }} />
                    </div>
                    <div>竣工日</div>
                    <div>
                        <DatePicker
                            style={{ width: '100%', height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={syunkoDate}
                            format="YYYY/MM/DD"
                            locale={locale}
                            // disabledDate={disabledDate}
                            onChange={changeSyunkoDate}
                            placeholder="年（西暦）/ 月/ 日"
                        />
                    </div>
                </div>
                {titleData.buken_shubetsu == 0 ?
                    <div className={classes.groundTwo} >
                        <div>延床面積</div>
                        <div>
                            <Input
                                style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                value={titleData.nobeyuka_area}
                                onChange={(e) => { changeTitleData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ""), 'nobeyuka_area') }}
                                maxLength={10}
                                placeholder="延床面積"
                            />
                            ㎡
                        </div>
                    </div>
                    :
                    <div className={classes.groundFour}>
                        <div>延床面積</div>
                        <div>
                            <Input
                                style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                value={titleData.nobeyuka_area}
                                onChange={(e) => { changeTitleData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ""), 'nobeyuka_area') }}
                                maxLength={10}
                                placeholder="延床面積"
                            />
                            ㎡
                        </div>
                        <div>基数</div>
                        <div>
                            <Input
                                style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                value={titleData.kisu}
                                onChange={(e) => { changeTitleData(e.target.value, 'kisu') }}
                                maxLength={10}
                                placeholder="基数"
                            />
                            基
                        </div>
                    </div>
                }
                <div className={classes.groundFour}>
                    <div>階数</div>
                    <div>
                        <Input
                            style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.floor}
                            onChange={(e) => { changeTitleData(e.target.value, 'floor') }}
                            maxLength={10}
                            placeholder="階数"
                        />
                        階
                    </div>
                    <div>部屋数</div>
                    <div>
                        <Input
                            style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.rooms}
                            onChange={(e) => { changeTitleData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ""), 'rooms') }}
                            maxLength={5}
                            placeholder="部屋数"
                        />
                        部屋
                    </div>
                </div>
                {titleData.buken_shubetsu == 1 &&
                    <div className={classes.groundFour}>
                        <div>補強部材</div>
                        <div>
                            <Input
                                style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                value={titleData.hokyo_buzai}
                                onChange={(e) => { changeTitleData(e.target.value, 'hokyo_buzai') }}
                                maxLength={20}
                                placeholder="補強部材"
                            />
                        </div>
                        <div>連結部材</div>
                        <div>
                            <Input
                                style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                value={titleData.renketu_buzai}
                                onChange={(e) => { changeTitleData(e.target.value, 'renketu_buzai') }}
                                maxLength={20}
                                placeholder="連結部材"
                            />
                        </div>
                    </div>}
                {titleData.buken_shubetsu == 1 &&
                    <div className={classes.groundTwo}>
                        <div>その他部材</div>
                        <div>
                            <Input
                                style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                value={titleData.other_buzai}
                                onChange={(e) => { changeTitleData(e.target.value, 'other_buzai') }}
                                maxLength={20}
                                placeholder="その他部材"
                            />
                        </div>
                    </div>}
                <div className={classes.groundFour}>
                    <div>駐輪場</div>
                    <div>
                        <Input
                            style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.tyurinjyo_num}
                            onChange={(e) => { changeTitleData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ""), 'tyurinjyo_num') }}
                            maxLength={5}
                            placeholder="駐輪場"
                        />
                        台
                    </div>
                    <div>駐車場</div>
                    <div>
                        <Input
                            style={{ width: VW(214), marginRight: VW(10), height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                            value={titleData.tyusyajyo_num}
                            onChange={(e) => { changeTitleData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ""), 'tyusyajyo_num') }}
                            maxLength={5}
                            placeholder="駐車場"
                        />
                        台
                    </div>
                </div>
                <div className={classes.TextAreaBlock}>
                    <div>備考<p style={{ fontSize: VW(14), fontWeight: '400' }}>（200文字まで）</p></div>
                    <div>
                        <TextArea
                            style={{ height: VH(104), width: "100%", fontSize: VW(16) }}
                            placeholder="備考を記入してください。"
                            maxLength={200}
                            value={titleData.remarks}
                            onChange={(e) => { changeTitleData(e.target.value, 'remarks') }}
                        />
                    </div>
                </div>
                {bodyList.length > 0 && bodyList.map((item, index) => {
                    return (
                        <div key={index}>
                            <Item_icon_title height={56} titleName={item.parent_category_name} titleId={item.parent_category_id} flag={item.openFlag} changeFlag={() => { changeOpenFlag(item) }} />
                            {item.data.length > 0 && item.data.map((secondItem, secondIndex) => {
                                return (
                                    <div key={secondIndex} style={{ display: item.openFlag ? "" : 'none' }}>
                                        {secondItem.category_name && <Item_secondary_title height={40} titleName={secondItem.category_name} />}
                                        {secondItem.result_data.key2_name &&
                                            <div className={classes.groundFour}>
                                                <div>{secondItem.result_data.key1_name}</div>
                                                <div>
                                                    <Input
                                                        style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                        value={secondItem.result_data.key1_value}
                                                        onChange={(e) => { secondItem.result_data.key1_name == '設置台数' ? changeBodyData(e.target.value.replace(/[^0-9+^\uFF10-\uFF19]+/g, ""), secondItem.result_data, 'key1_value') : changeBodyData(e.target.value, secondItem.result_data, 'key1_value') }}
                                                        maxLength={secondItem.result_data.key1_name == '設置台数' ? 5 : secondItem.result_data.key1_name == '施工業者' ? 60 : ""}
                                                        placeholder={secondItem.result_data.key1_name}
                                                    />
                                                </div>
                                                <div>{secondItem.result_data.key2_name}</div>
                                                <div>
                                                    <Input
                                                        style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                        value={secondItem.result_data.key2_value}
                                                        onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key2_value') }}
                                                        maxLength={(secondItem.result_data.key2_name == '設置種類' || secondItem.result_data.key2_name == '補強部材' || secondItem.result_data.key2_name == '連結部材' || secondItem.result_data.key2_name == '枠部材') ? 20 : secondItem.result_data.key2_name == '電話番号' ? 15 : ''}
                                                        placeholder={secondItem.result_data.key2_name}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {secondItem.result_data.key4_name &&
                                            <div className={classes.groundFour}>
                                                <div>{secondItem.result_data.key3_name}</div>
                                                <div>
                                                    <Input
                                                        style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                        value={secondItem.result_data.key3_value}
                                                        onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key3_value') }}
                                                        maxLength={(secondItem.result_data.key3_name == 'メーカー' || secondItem.result_data.key3_name == '連結部材') ? 20 : ""}
                                                        placeholder={secondItem.result_data.key3_name}
                                                    />
                                                </div>
                                                <div>{secondItem.result_data.key4_name}</div>
                                                <div>
                                                    <Input
                                                        style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                        value={secondItem.result_data.key4_value}
                                                        onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key4_value') }}
                                                        maxLength={secondItem.result_data.key4_name == 'その他部材' ? 20 : secondItem.result_data.key4_name == '電話番号' ? 15 : ''}
                                                        placeholder={secondItem.result_data.key4_name}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {secondItem.result_data.key6_name &&
                                            <div className={classes.groundFour}>
                                                <div>{secondItem.result_data.key5_name}</div>
                                                <div>
                                                    <Input
                                                        style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                        value={secondItem.result_data.key5_value}
                                                        onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key5_value') }}
                                                        maxLength={secondItem.result_data.key5_name == 'メーカー' ? 20 : ""}
                                                        placeholder={secondItem.result_data.key5_name}
                                                    />
                                                </div>
                                                <div>{secondItem.result_data.key6_name}</div>
                                                <div>
                                                    <Input
                                                        style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                        value={secondItem.result_data.key6_value}
                                                        onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key6_value') }}
                                                        placeholder={secondItem.result_data.key6_name}
                                                        maxLength={secondItem.result_data.key6_name == '電話番号' ? 15 : ''}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {secondItem.result_data.key8_name &&
                                            <div className={classes.groundFour}>
                                                <div>{secondItem.result_data.key7_name}</div>
                                                <div>
                                                    <Input
                                                        style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                        value={secondItem.result_data.key7_value}
                                                        onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key7_value') }}
                                                        placeholder={secondItem.result_data.key7_name}
                                                    />
                                                </div>
                                                <div>{secondItem.result_data.key8_name}</div>
                                                <div>
                                                    <Input
                                                        style={{ height: VH(40), fontSize: VW(16), borderRadius: '4px' }}
                                                        value={secondItem.result_data.key8_value}
                                                        onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'key8_value') }}
                                                        placeholder={secondItem.result_data.key8_name}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className={classes.TextAreaBlock}>
                                            <div>備考<p style={{ fontSize: VW(14), fontWeight: '400' }}>（200文字まで）</p></div>
                                            <div>
                                                <TextArea
                                                    style={{ height: VH(104), width: "100%", fontSize: VW(16) }}
                                                    placeholder="備考を記入してください。"
                                                    maxLength={200}
                                                    value={secondItem.result_data.remark_value}
                                                    onChange={(e) => { changeBodyData(e.target.value, secondItem.result_data, 'remark_value') }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
                <div style={{ width: '100%', borderTop: '1px solid #DDD' }}></div>
                {titleData.buken_shubetsu == 0 ?
                    <div className={classes.buttonGroupThree}>
                        <Button onClick={onClose}>キャンセル</Button>
                        <Button onClick={toAdd}>登録</Button>
                        <Button onClick={addEquipment}>部屋登録へ</Button>
                    </div>
                    :
                    <div className={classes.buttonGroup}>
                        <Button onClick={onClose}>キャンセル</Button>
                        <Button onClick={toAdd}>登録</Button>
                    </div>
                }
            </div>
            <OwnersList_add
                open={addOpen}
                onClose={addOnClose}
                getList={getOwnerList}
            />
            <Property_img_upload
                open={uploadOpen}
                onClose={uploadOnClose}
                imgName={imgName}
                imageBukenId={imageBukenId}
                images={images}
                setImages={setImages}
                imgBody={imgBody}
            />
        </Modal >
    )
}

Property_add.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Property_add);