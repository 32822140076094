import React, { useState, useEffect, useRef } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button } from 'antd';
import AddIcon from "../icon/AddIcon";
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import UploadFiles from "../common/UploadFiles";
import { useDropzone } from 'react-dropzone';
import { ApiBukenFileUploadBase, ApiIntegrationFileUpload } from '../../network/api'
import ReactDOM from 'react-dom/client';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { uploadFile } from "../function/UploadS3";


const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
        }
    },
    uploadBlock: {
        width: '100%',
        minHeight: VH(280),
        marginTop: VH(13)
    }
});

function Property_csv_upload(props) {
    const {
        classes,
        open,
        onClose,
        getList
    } = props

    useEffect(() => {
        if (!open) {
            setAcceptedFilesStandIn([])
            setErrorList([])
            setRrrorOpen(false)
        }
    }, [open]);


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState("")

    // file list
    var { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 1
    });
    const [acceptedFilesStandIn, setAcceptedFilesStandIn] = useState([])
    useEffect(() => {
        log(acceptedFiles)
        if (acceptedFiles[0] && acceptedFiles[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setAcceptedFilesStandIn([...acceptedFiles])
        } else {
            setAcceptedFilesStandIn([])
        }
    }, [acceptedFiles]);



    const [errorList, setErrorList] = useState([])
    const closeErrorItem = (item) => {
        errorList.map(ll => {
            if (ll.errorKey == item.errorKey) {
                ll.openFlag = false
            }
        })
        setErrorList([...errorList])
        log(item)
    }



    // 显示loading
    function showLoading() {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        ReactDOM.createRoot(dom).render(
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
                <div style={{ fontWeight: "bold", color: '#DDD' }}>処理中です</div>
                <div style={{ fontWeight: "bold", color: '#DDD' }}>しばらくお待ちください</div>
            </div>
        )
    }
    // 隐藏loading
    function hideLoading() {
        document.body.removeChild(document.getElementById('loading'))
    }


    // upload
    const newData = useRef('')
    const fileName = useRef('')
    const toAdd = () => {
        if (acceptedFilesStandIn.length == 0) {
            setErrorContent('ファイルをアップロードして下さい')
            setRrrorOpen(true)
            return
        } else {
            setRrrorOpen(false)
            newData.current = dayjs().format("YYYYMMDDHHmmss")
            let newList = []
            acceptedFilesStandIn.map(item => {
                fileName.current = item.name
                newList.push(`buken_base_upload/${newData.current}/${item.name}`)
            })
            let params = {
                file_path: newList,
            };
            ApiIntegrationFileUpload(params).then(res => {
                let resData = res.data
                if (res.status === 200) {
                    showLoading()
                    resData.file_url.map((item, index) => {
                        uploadFile(item.url, acceptedFilesStandIn[index], item.content_type)
                            .then(data => {
                                hideLoading()
                                let params = {
                                    upload_time: newData.current,
                                    file_name: fileName.current
                                }
                                ApiBukenFileUploadBase(params).then(res => {
                                    let resData = res.data
                                    if (res.status == 200) {
                                        if (resData.data == 'Failure') {
                                            resData.error_message.map((item, index) => {
                                                item.openFlag = true
                                                item.errorKey = index
                                            })
                                            setErrorList(resData.error_message)
                                        } else {
                                            onClose()
                                            setRrrorOpen(false)
                                            getList('add')
                                        }
                                    } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                                        log(res)
                                        setRrrorOpen(true)
                                        setErrorContent(res.data.detail.toString())
                                    }
                                }).catch(err => {

                                })
                            })
                            .catch(error => {
                                hideLoading()
                                setErrorContent('アップロードに失敗しました')
                                setRrrorOpen(true)
                            });
                    })

                } else {
                    hideLoading()
                    setErrorContent(resData)
                    setRrrorOpen(true)
                }
            }).catch(err => {
            })
        }
    }



    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div>
                <div className={classes.tableTitle}>
                    <AddIcon size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>物件付帯情報の登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                {errorList.length > 0 && errorList.map((item, index) => {
                    return (
                        <Common_errorAlert
                            key={index}
                            open={item.openFlag}
                            setRrrorOpen={setRrrorOpen}
                            errorContent={item.detail}
                            listError={true}
                            closeErrorItem={() => { closeErrorItem(item) }}
                            border
                        />
                    )
                })}
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.uploadBlock}>
                    <UploadFiles
                        acceptedFiles={acceptedFilesStandIn}
                        getRootProps={getRootProps}
                        getInputProps={getInputProps}
                        type='EXCEL'
                    />
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={toAdd}>物件付帯情報を読み込む</Button>
                </div>
            </div>
        </Modal >
    )
}

Property_csv_upload.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Property_csv_upload);