import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import Item_four from "../../componets/common/Item_four";
import Item_two from "../../componets/common/Item_two";
import Item_icon_title from "../../componets/common/Item_icon_title";
import Item_secondary_title from "../../componets/common/Item_secondary_title";
import { VW, VH } from "../../componets/function/sizeAdapter"


const styles = (theme) => ({
    ground: {
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        '& > div': {
            display: 'flex',
            fontSize: VW(16),
            paddingLeft: VW(16),
            alignItems: "center",
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            height: VH(56),
            alignItems: 'center'
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            width: '80%'
        }
    },
    imgGround: {
        width: '100%',
        display: 'flex',
        borderTop: '1px solid #DDD',
        minHeight: VH(56),
        '& > div': {
            display: 'flex',
            fontSize: VW(16),
            paddingLeft: VW(16),
            alignItems: 'center'
        },
        '& > div:nth-child(1)': {
            width: '20%',
            fontWeight: 'bold',
            backgroundColor: '#F8F8F8'
        },
        '& > div:nth-child(2)': {
            padding: '6px 16px',
            width: '80%',
            '& > div': {
                width: VW(168),
                maxHeight: VH(168),
                display: 'flex',
                alignItems: 'center',
            }
        }
    },
});

function PropertyDetail_item_First(props) {
    const {
        classes,
        titleData,
        state,
        bodyList,
        changeOpenFlag,
        images,
    } = props

    const authority_id = localStorage.getItem("authority_id")

    return (
        <div style={{ marginTop: VH(16) }}>
            {(authority_id == 1 || authority_id == 2 || authority_id == 5) &&
                <div className={classes.ground}>
                    <div style={{ height: VH(56) }}>業務管理システム物件CD</div>
                    <div style={{ height: VH(56) }}>
                        <div style={{ width: '100%', maxHeight: VH(56) }}>{!titleData.mansion_buken_code ? '' : titleData.mansion_buken_code + '(' + titleData.mansion_buken_name + ')'}</div>
                    </div>
                </div>
            }
            {authority_id == 3 || authority_id == 4 ?
                <div className={classes.ground}>
                    <div style={{ height: VH(56) }}>物件CD</div>
                    <div style={{ height: VH(56) }}>{titleData.customer_buken_code}</div>
                </div>
                :
                <Item_four
                    height={56}
                    titleName={['パパネッツ物件CD', '物件CD']}
                    content={[titleData.papanets_buken_code, titleData.customer_buken_code]}
                />
            }
            {images.length > 0 &&
                <div className={classes.imgGround}>
                    <div>物件画像</div>
                    <div>
                        {images.length > 0 &&
                            <div>
                                <img
                                    src={images[0].s3_image_url ?? ''}
                                    style={{ width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center' }}
                                />
                            </div>
                        }
                    </div>
                </div>
            }
            <Item_four
                height={56}
                titleName={['パパネッツ\n巡回契約開始日', 'パパネッツ\n巡回契約終了日']}
                content={[
                    titleData.keiyaku_start_date,
                    titleData.keiyaku_end_date
                ]}
            />
            {authority_id == 3 || authority_id == 4 ?
                <div className={classes.ground}>
                    <div style={{ height: VH(56) }}>エリア</div>
                    <div style={{ height: VH(56) }}>{titleData.area}</div>
                </div>
                :
                <Item_four
                    height={56}
                    titleName={['エリア', '管理会社']}
                    content={[titleData.area, titleData.tenant_name]}
                />
            }
            <div className={classes.ground}>
                <div style={{ height: VH(56) }}>物件名</div>
                <div style={{ height: VH(56) }}>{titleData.genba_name}</div>
            </div>
            <div className={classes.ground}>
                <div style={{ height: VH(56) }}>郵便番号</div>
                <div style={{ height: VH(56) }}>{titleData.postal ? '〒' + titleData.postal : '〒'}</div>
            </div>
            <div className={classes.ground}>
                <div style={{ height: VH(56) }}>所在地</div>
                <div style={{ height: VH(56) }}>{titleData.address}</div>
            </div>
            <Item_four
                height={56}
                titleName={['オーナー', '竣工日']}
                content={[titleData.owner_name, titleData.syunko_date ? titleData.syunko_date + titleData.syunko_date_context : ""]}
            />
            {state.buken_shubetsu === 0 ?
                <div className={classes.ground}>
                    <div style={{ height: VH(56) }}>延床面積</div>
                    <div style={{ height: VH(56) }}>{titleData.nobeyuka_area ? titleData.nobeyuka_area + '㎡' : ''}</div>
                </div>
                :
                <Item_four
                    height={56}
                    titleName={['延床面積', '基数']}
                    content={[titleData.nobeyuka_area ? titleData.nobeyuka_area + '㎡' : '', titleData.kisu ? titleData.kisu + '基' : '']}
                />
            }
            <Item_four
                height={56}
                titleName={['階数', '部屋数']}
                content={[titleData.floor ? titleData.floor + '階' : '', titleData.rooms ? titleData.rooms + '部屋' : '']}
            />
            {state.buken_shubetsu === 1 &&
                <Item_four
                    height={56}
                    titleName={['補強部材', '連結部材']}
                    content={[titleData.hokyo_buzai, titleData.renketu_buzai]}
                />
            }
            {state.buken_shubetsu === 1 &&
                <div className={classes.ground}>
                    <div style={{ height: VH(56) }}>その他部材</div>
                    <div style={{ height: VH(56) }}>{titleData.other_buzai}</div>
                </div>
            }
            <Item_four
                height={56}
                titleName={['駐輪場', '駐車場']}
                content={[titleData.tyurinjyo_num ? titleData.tyurinjyo_num + '台' : '', titleData.tyusyajyo_num ? titleData.tyusyajyo_num + '台' : '']}
            />
            <Item_two
                height={120}
                titleName='備考'
                content={titleData.remarks}
            />

            {bodyList.length > 0 && bodyList.map((item, index) => {
                return (
                    <div key={index}>
                        <Item_icon_title height={56} titleName={item.parent_category_name} titleId={item.parent_category_id} flag={item.openFlag} changeFlag={() => { changeOpenFlag(item) }} />
                        {item.data.length > 0 && item.data.map((secondItem, secondIndex) => {
                            return (
                                <div key={secondIndex} style={{ display: item.openFlag ? '' : 'none' }}>
                                    {secondItem.category_name && <Item_secondary_title height={40} titleName={secondItem.category_name} />}
                                    {secondItem.result_data.key2_name && < Item_four height={56} titleName={[secondItem.result_data.key1_name, secondItem.result_data.key2_name]} content={[secondItem.result_data.key1_value, secondItem.result_data.key2_value]} />}
                                    {secondItem.result_data.key4_name && < Item_four height={56} titleName={[secondItem.result_data.key3_name, secondItem.result_data.key4_name]} content={[secondItem.result_data.key3_value, secondItem.result_data.key4_value]} />}
                                    {secondItem.result_data.key6_name && < Item_four height={56} titleName={[secondItem.result_data.key5_name, secondItem.result_data.key6_name]} content={[secondItem.result_data.key5_value, secondItem.result_data.key6_value]} />}
                                    {secondItem.result_data.key8_name && < Item_four height={56} titleName={[secondItem.result_data.key7_name, secondItem.result_data.key8_name]} content={[secondItem.result_data.key7_value, secondItem.result_data.key8_value]} />}
                                    <Item_two height={120} titleName={secondItem.result_data.remark} content={secondItem.result_data.remark_value} />
                                </div>
                            )
                        })}
                    </div>
                )
            })}
            <div style={{ width: '100%', borderTop: '1px solid #DDD' }}></div>
        </div>
    )
}

PropertyDetail_item_First.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail_item_First);