import { ArrowForwardIos } from "@mui/icons-material";
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Button } from 'antd';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RealEstateDetail_input from "../../componets/HtmlBody/RealEstateDetail_input";
import RealEstateDetail_item from "../../componets/HtmlBody/RealEstateDetail_item";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import RealEstate_delete from "../../componets/dialog/RealEstate_delete";
import log from "../../componets/function/log";
import { VH, VW } from "../../componets/function/sizeAdapter";
import dic from "../../componets/function/utc";
import MenuHeader_house from "../../componets/icon/MenuHeader_house";
import { ApiCustomerList, ApiTenantUpdate } from '../../network/api';
import { errorMessage, routerNames } from '../../wordsFile/wordsFile';

dayjs.extend(utc)
const styles = (theme) => ({
    ground: {
        width: '100%',
        paddingRight: VW(24),
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
            paddingRight: VW(24)
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    updateDeleteBlock: {
        position: 'absolute',
        right: 0,
        display: 'flex',
        alignItems: 'center',
        marginTop: VH(16),
        justifyContent: 'flex-end',
        '& > Button': {
            fontSize: VW(16),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& > Button:nth-child(2)': {
            color: '#E60024',
        }
    },
    bottomButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VW(40),
        marginBottom: VW(40),
        '& > Button': {
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function RealEstateDetail(props) {
    const {
        classes,
        setSelectFlag
    } = props


    useEffect(() => {
        setSelectFlag(routerNames.RealEstate)
        log(state)
    }, [setSelectFlag])

    const location = useLocation();
    const { state } = location;

    var authority_id = localStorage.getItem("authority_id")

    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')
    // add error email
    const [errorEmailOpen, setErrorEmailOpen] = useState(false)
    const [errorEmailContent, setErrorEmailContent] = useState('')



    const [updateFlag, setUpdateFlag] = useState(false)

    // detailed list data
    const [falseData, setFalseData] = useState(state)

    // delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteOpen(true)
    }

    // RealEstateDetail_input
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  会社名
    const [tenantName, setTenantName] = useState(falseData.tenant_name)
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  カルテ契約開始日
    // const [startDay,setStartDay] = useState(falseData.karte_contract_date_start ? dayjs(falseData.karte_contract_date_start) : '')
    // const [startValue, setStartValue] = useState(falseData.karte_contract_date_start);
    // const changeStartDay = (value, dateString) => {
    //     setStartDay(value)
    //     setStartValue(dateString);
    //     log(dateString)
    // };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  カルテ契約終了日
    const [endDay, setEndDay] = useState(falseData.karte_contract_date_end ? dayjs(falseData.karte_contract_date_end) : '')
    const [endValue, setEndValue] = useState(falseData.karte_contract_date_end);
    const changeEndDay = (value, dateString) => {
        setEndDay(value)
        setEndValue(dateString);
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  管理者 list
    const [administratorList, setAdministratorList] = useState([])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  管理者追加
    const addAdministratorListItem = () => {
        administratorList.push({ surname: '', name: '', email: '' })
        setAdministratorList([...administratorList])
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  list
    const updateAdministratorList = (item, index, flag) => {
        administratorList[index][flag] = item
        setAdministratorList([...administratorList])
        log(administratorList)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  得意先
    const [customerList, setCustomerList] = useState([])
    const addCustomerListItem = () => {
        customerList.push({
            code: undefined
        })
        setCustomerList([...customerList])
    }
    const updateCustomerList = (item, index) => {
        delete customerList[index].codeAndName
        customerList[index]['code'] = item
        setCustomerList([...customerList])
    }
    const deleteCustomer = (index) => {
        customerList.splice(index, 1)
        setCustomerList([...customerList])
    }
    const isDisabledOption = (item) => {
        const filteredCustomerList = customerList.filter(item => item !== undefined)
        const isDisabled = filteredCustomerList.includes(item.code) || item.isSelected || item.status == 9
        return isDisabled
    }



    // update api
    const toUpdate = () => {
        document.getElementById('father_Block').scrollTop = 0;
        let InformationFlag = false
        administratorList.map(item => {
            if ((item.surname === '' && (item.name != '' || item.email != '')) || (item.name === '' && (item.surname != '' || item.email != '')) || (item.email === '' && (item.name != '' || item.surname != ''))) {
                InformationFlag = true
            }
        })
        if (tenantName === '') {
            setErrorContent(errorMessage.tenantNameNull)
            setRrrorOpen(true)
            setSuccessOpen(false)
        }
        else if (InformationFlag) {
            setErrorContent(errorMessage.administratorNull)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else {
            let userList = []
            administratorList.map(item => {
                if (item.surname != '') {
                    userList.push(item)
                }
            })
            let newCodeList = []

            customerList.map(item => {
                if (item.codeAndName) {
                    newCodeList.push(item.codeAndName.split('(')[0])
                } else if (item.code) {
                    newCodeList.push(item.code)
                }
            })
            let customerCode = newCodeList.filter(item => item !== undefined).join(",")
            let params = {
                tenant_id: state.tenant_id,
                tenant_name: tenantName,
                user_masters: userList,
                // karte_contract_date_start : startValue === '' ? null : dayjs.utc(startValue).format("YYYY-MM-DD HH:mm:ss"),
                karte_contract_date_end: endValue === '' ? null : endValue,
                customer_code: customerCode === '' ? null : customerCode
            }
            log(params)
            ApiTenantUpdate(params).then(res => {
                if (res.status === 200) {
                    log(res.data)
                    let item = res.data.data[0]
                    item.login_status = item.login_status === '3' ? 'correct' : item.login_status === '2' ? 'wrong' : ''
                    item.representative = item.user_master.length > 0 ? item.user_master[0].surname + item.user_master[0].name + '（他' + item.user_master.length + '名）' : ''
                    item.email = item.user_master.length > 0 ? item.user_master[0].email : ''
                    item.create_date = dic.UTCDateToLocalDate(item.create_date)
                    item.karte_contract_date_start = item.karte_contract_date_start ? dic.UTCDateToLocalDate(item.karte_contract_date_start) : ''
                    item.karte_contract_date_end = item.karte_contract_date_end ? dic.UTCDateToLocalDate(item.karte_contract_date_end) : ''
                    setFalseData(item)

                    if (res.data.error_email.length > 0) {
                        setSuccessOpen(false)
                        let errConcent = ''
                        res.data.error_email.map(errorItem => {
                            errConcent = errConcent + errorItem.email + '\n'
                        })
                        setErrorEmailContent(errConcent)
                        setErrorEmailOpen(true)
                    } else {
                        setErrorEmailOpen(false)
                        setSuccessOpen(true)
                        setSuccessContent('更新が完了しました。')
                    }
                    setUpdateFlag(false)
                } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                    log(res)
                    setRrrorOpen(true)
                    setErrorContent(res.data.detail.toString())
                }
            }).catch(err => {

            })
        }

    }

    // customer option list api
    const [customerOption, setCustomerOption] = useState([]);
    const getCustomerOption = () => {
        let params = {
            sortKey: 'seikyusaki_id',
            sortValue: 'asc',
        }
        ApiCustomerList(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                setCustomerOption(resData.data)
            }
        })
    }

    useEffect(() => {
        getCustomerOption()
        if (!updateFlag) {
            setTenantName(falseData.tenant_name)
            // setStartDay(falseData.karte_contract_date_start ? dayjs(falseData.karte_contract_date_start) : '')
            // setStartValue(falseData.karte_contract_date_start)
            setEndDay(falseData.karte_contract_date_end ? dayjs(falseData.karte_contract_date_end) : '')
            setEndValue(falseData.karte_contract_date_end)
            setAdministratorList([])
        } else {
            setSuccessOpen(false)
            setErrorEmailOpen(false)
        }
    }, [updateFlag])

    useEffect(() => {
        if (state.customer_master.length > 0) {
            state.customer_master.map(item => {
                item.codeAndName = item.code + '(' + item.name + ')'
            })
            setCustomerList([...state.customer_master])
        }
    }, [])


    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}><span style={{ cursor: 'pointer' }} onClick={() => { window.history.back() }}>不動産管理会社一覧</span><ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>不動産管理会社情報</div>
            <div className={classes.tableTitle}>
                <MenuHeader_house />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>{updateFlag ? '不動産管理会社情報の編集' : '不動産管理会社情報'}</div>
                <div className={classes.updateDeleteBlock}>
                    {!updateFlag &&
                        <>
                            <Button style={{ padding: 0, marginRight: authority_id == '5' ? VW(24) : 0 }} type="text" icon={<CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={() => { setUpdateFlag(true) }}>&nbsp;編集</Button>
                            {authority_id == '5' && <Button style={{ padding: 0 }} type="text" danger icon={<DeleteOutlineIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} />} onClick={openDeleteDialog}>&nbsp;削除</Button>}
                        </>
                    }
                </div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorEmailOpen}
                setRrrorOpen={setErrorEmailOpen}
                errorContent={errorEmailContent}
                border
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
            />
            {
                !updateFlag ?
                    <RealEstateDetail_item
                        falseData={falseData}
                        setRrrorOpen={setRrrorOpen}
                        setErrorContent={setErrorContent}
                        setSuccessOpen={setSuccessOpen}
                        setSuccessContent={setSuccessContent}
                    />
                    :
                    <RealEstateDetail_input
                        falseData={falseData}
                        tenantName={tenantName}
                        setTenantName={setTenantName}
                        // startDay = { startDay }
                        // changeStartDay = { changeStartDay }
                        endDay={endDay}
                        changeEndDay={changeEndDay}
                        administratorList={administratorList}
                        updateAdministratorList={updateAdministratorList}
                        addAdministratorListItem={addAdministratorListItem}
                        customerOption={customerOption}
                        customerList={customerList}
                        setCustomerList={setCustomerList}
                        addCustomerListItem={addCustomerListItem}
                        updateCustomerList={updateCustomerList}
                        deleteCustomer={deleteCustomer}
                        isDisabledOption={isDisabledOption}
                    />
            }
            <div className={classes.bottomButton}>
                {!updateFlag ?
                    <Button onClick={() => { window.history.back() }}>管理会社一覧へ戻る</Button>
                    :
                    <>
                        <Button onClick={() => { setUpdateFlag(false) }}>キャンセル</Button>
                        <Button onClick={toUpdate}>更新</Button>
                    </>
                }
            </div>
            <RealEstate_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={[falseData]}
                historyFlag={true}
            />
        </div >
    )
}

RealEstateDetail.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(RealEstateDetail);