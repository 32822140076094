import React, { useState, useEffect, useRef } from "react";
import {

} from "@mui/material";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button } from 'antd';
import AddIcon from "../icon/AddIcon";
import Common_errorAlert from "../common/Common_errorAlert";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import UploadFiles from "../common/UploadFiles";
import { useDropzone } from 'react-dropzone';
import ReactDOM from 'react-dom/client';
import CircularProgress from '@mui/material/CircularProgress';
import { uploadFile } from '../function/UploadS3'
import { ApiIntegrationFileUpload } from '../../network/api'


const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        },
        '& .ant-select-single .ant-select-selector': {
            borderRadius: '4px',
            height: VH(50),
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25vw'
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
        }
    },
    uploadBlock: {
        width: '100%',
        minHeight: VH(280),
        marginTop: VH(13)
    }
});

function Property_img_upload(props) {
    const {
        classes,
        open,
        onClose,
        imgName,
        imageBukenId,
        images,
        setImages,
        imgBody
    } = props

    useEffect(() => {
        if (!open) {
            setAcceptedFilesStandIn([])
            setRrrorOpen(false)
        }
    }, [open]);


    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState("")

    // file list
    var { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        // maxFiles: 1
    });
    const [acceptedFilesStandIn, setAcceptedFilesStandIn] = useState([])
    useEffect(() => {
        log(acceptedFiles)
        if (acceptedFiles[1]) {
            setErrorContent('登録できる画像は1枚までです。')
            setRrrorOpen(true)
            setAcceptedFilesStandIn([])
        } else {
            if (acceptedFiles[0] && (acceptedFiles[0].type == 'image/png' || acceptedFiles[0].type == 'image/jpeg')) {
                setAcceptedFilesStandIn([...acceptedFiles])
                setRrrorOpen(false)
            } else {
                if (acceptedFiles[0]) {
                    setErrorContent('画像をアップロードしてください。')
                    setRrrorOpen(true)
                    setAcceptedFilesStandIn([])
                }
            }
        }
    }, [acceptedFiles]);



    // 显示loading
    function showLoading() {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        ReactDOM.createRoot(dom).render(
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
                <div style={{ fontWeight: "bold", color: '#DDD' }}>処理中です</div>
                <div style={{ fontWeight: "bold", color: '#DDD' }}>しばらくお待ちください</div>
            </div>
        )
    }
    // 隐藏loading
    function hideLoading() {
        document.body.removeChild(document.getElementById('loading'))
    }


    // upload
    const toAdd = () => {
        if (acceptedFilesStandIn.length == 0) {
            setErrorContent('画像をアップロードしてください。')
            setRrrorOpen(true)
            return
        } else if (acceptedFilesStandIn[0].size > 209715200) {
            setErrorContent('登録できるファイルサイズは1ファイル200MBまでです。')
            setRrrorOpen(true)
            return
        } else {
            setRrrorOpen(false)
            let newList = []
            acceptedFilesStandIn.map(item => {
                newList.push(`buken_image/${imageBukenId.current + '.' + item.name.split('.').pop()}`)
            })
            let params = {
                file_path: newList,
            };
            ApiIntegrationFileUpload(params).then(res => {
                let resData = res.data
                if (res.status === 200) {
                    showLoading()
                    resData.file_url.map((item, index) => {
                        uploadFile(item.url, acceptedFilesStandIn[index], item.content_type)
                            .then(data => {
                                hideLoading()
                                images.push({
                                    's3_image_url': URL.createObjectURL(acceptedFilesStandIn[index]),
                                    'image_key': `buken_image/${imageBukenId.current + '.' + acceptedFilesStandIn[index].name.split('.').pop()}`
                                })
                                setImages([...images])
                                log(images)
                                setAcceptedFilesStandIn([])
                                onClose()
                            })
                            .catch(error => {
                                hideLoading()
                                setErrorContent('アップロードに失敗しました')
                                setRrrorOpen(true)
                            });
                    })

                } else {
                    setErrorContent(resData)
                    setRrrorOpen(true)
                }
            }).catch(err => {

            })
        }
    }


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(1056)}
            closable={false}
        >
            <div>
                <div className={classes.tableTitle}>
                    <AddIcon size={VW(16.67)} />
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>物件画像の登録</div>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                <Common_errorAlert
                    open={errorOpen}
                    setRrrorOpen={setRrrorOpen}
                    errorContent={errorContent}
                    border
                />
                <div className={classes.uploadBlock}>
                    <UploadFiles
                        acceptedFiles={acceptedFilesStandIn}
                        getRootProps={getRootProps}
                        getInputProps={getInputProps}
                    />
                </div>
                <div className={classes.buttonGroup}>
                    <Button onClick={toAdd}>画像を読み込む</Button>
                </div>
            </div>
        </Modal >
    )
}

Property_img_upload.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Property_img_upload);