import React, { useEffect, useState, useRef } from "react";
import {

} from "@mui/material";
import { withStyles } from "@mui/styles";
import { routerNames } from '../../wordsFile/wordsFile'
import MenuHeader_property from "../../componets/icon/MenuHeader_property";
import log from "../../componets/function/log";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../../componets/function/sizeAdapter"
import { ArrowForwardIos, FastfoodOutlined } from "@mui/icons-material";
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button } from 'antd'
import { useLocation } from "react-router-dom";
import { ApiBukenDetail, ApiBukenUpdate, ApiOwnerOwnerNameByTenantid, ApiBukenUpdateKeiyaku, ApiBukenDetailExit } from '../../network/api'
import PropertyDetail_item_First from "../../componets/HtmlBody/PropertyDetail_item_First";
import PropertyDetail_input_First from "../../componets/HtmlBody/PropertyDetail_input_First";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import Common_successAlert from "../../componets/common/Common_successAlert";
import { errorMessage, formatCheck } from "../../wordsFile/wordsFile";
import Property_delete from "../../componets/dialog/Property_delete";
import { useNavigate } from 'react-router-dom'

dayjs.extend(utc)

const styles = (theme) => ({
    ground: {
        width: '100%',
        '& > div:not(:nth-child(1))': {
            marginLeft: VW(24),
            marginRight: VW(24),
        }
    },
    routerBlcok: {
        color: '#44566C',
        fontSize: VW(12),
        width: '100%',
        height: VH(32),
        paddingTop: VH(8),
        paddingLeft: VW(8),
        display: 'flex',
        alignItems: 'center',
    },
    tableTitle: {
        marginTop: VH(16),
        marginLeft: VW(24),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        position: 'relative',
        '& > div:nth-child(2)': {
            marginLeft: VW(8),
        }
    },
    retrievalBlock: {
        marginLeft: VW(24),
        marginTop: VH(12),
        minHeight: VH(24),
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-line',
        wordBreak: 'break-all',
        '& > div:nth-child(1)': {
            fontSize: VW(14),
        },
        '& > div:nth-child(2)': {
            fontSize: VW(24),
            fontWeight: 'bold',
            marginLeft: VW(16)
        }
    },
    titleMenu: {
        width: 'auto',
        display: 'flex',
        marginTop: VH(16),
        height: VH(40),
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    menuContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: VW(16)
    },
    updateButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: VH(16),
        height: VH(24),
        '& > div': {
            width: 'auto',
            height: VH(24),
            display: 'flex',
            cursor: 'pointer',
            '& > div:nth-child(2)': {
                fontSize: VW(16)
            }
        }
    },
    bottomButton: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VW(40),
        marginBottom: VW(24),
        '& > Button': {
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    }
});

function PropertyDetail(props) {
    const {
        classes,
        setSelectFlag
    } = props

    const location = useLocation();
    const { state } = location;


    useEffect(() => {
        setSelectFlag(routerNames.Property)
    }, [setSelectFlag])


    useEffect(() => {
        getDetailList()
        log(state)
    }, [])

    const navigate = useNavigate()

    const authority_id = localStorage.getItem("authority_id")

    // const authority_id = "5";
    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')

    const [ownerList, setOwnerList] = useState([])

    // detail list api
    const getDetailList = () => {
        document.getElementById('father_Block').scrollTop = 0;
        let param = {
            buken_id: state.buken_id
        }
        ApiBukenDetail(param).then(res => {
            let resData = res.data
            if (res.status == 200) {
                log(resData)
                resData.data.tag_color = resData.data.tag_color == '' ? [] : resData.data.tag_color.indexOf(',') != '-1' ? resData.data.tag_color.split(',') : [resData.data.tag_color]
                setFalseData(resData.data)
                if (resData.data.s3_image_url) {
                    let newImgList = []
                    newImgList.push({ s3_image_url: resData.data.s3_image_url, image_key: resData.data.buken_image_url })
                    setImages([...newImgList]);
                }
                if (resData.data.syunko_date) {
                    setSyunkoDate(dayjs(resData.data.syunko_date))
                    setSyunkoValue(resData.data.syunko_date)
                }
                if (resData.data.keiyaku_start_date) {
                    setStartDate(dayjs(resData.data.keiyaku_start_date))
                    setStartValue(resData.data.keiyaku_start_date)
                }
                if (resData.data.keiyaku_end_date) {
                    setEndDate(dayjs(resData.data.keiyaku_end_date))
                    setEndValue(resData.data.keiyaku_end_date)
                }
                setTitleData({ ...resData.data })
                setMansion_buken_code(resData.data.mansion_buken_code)
                setMansion_buken_name(resData.data.mansion_buken_name)
                resData.data.data.map(item => {
                    item.openFlag = false
                })
                setBodyList([...resData.data.data])
                if (authority_id == 3 || authority_id == 4) {
                    let params = {
                        // tenant_id: resData.data.tenant_id,
                        query_content: ''
                    }
                    ApiOwnerOwnerNameByTenantid(params).then(ownerItem => {
                        let ownerData = ownerItem.data
                        if (ownerItem.status == 200) {
                            log(ownerData)
                            ownerData.data.push({ owner_name: 'オーナー不明' })
                            setOwnerList([...ownerData.data])
                        } else if (ownerItem.status >= 300 && ownerItem.status < 500 && ownerItem.status != 401) {
                            log(ownerItem)
                            setSuccessOpen(false)
                            setRrrorOpen(true)
                            setErrorContent(ownerItem.data.detail.toString())
                        }
                    })
                }
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        })
    }

    // get owner list
    const getOwnerList = (flag) => {
        let params = {
            // tenant_id: titleData.tenant_id,
            query_content: ''
        }
        ApiOwnerOwnerNameByTenantid(params).then(res => {
            let resData = res.data
            if (res.status == 200) {
                log(resData)
                resData.data.push({ owner_name: 'オーナー不明' })
                setOwnerList([...resData.data])
                changeTitleData(resData.data[resData.data.length - 2].owner_name, 'owner_name')
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        })
    }



    // update flag
    const [updateFlag, setUpdateFlag] = useState(false)
    // title data
    const [titleData, setTitleData] = useState({})
    // body array
    const [bodyList, setBodyList] = useState([])
    //change titleData
    const changeTitleData = (value, type) => {
        if (type == 'owner_name') {
            if (!value) {
                titleData[type] = ''
                setTitleData({ ...titleData })
            } else {
                titleData[type] = value
                setTitleData({ ...titleData })
            }
        } else {
            titleData[type] = value
            setTitleData({ ...titleData })
        }
        log(value)
        log(type)
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  カルテ契約終了日
    const [syunkoDate, setSyunkoDate] = useState('')
    const [syunkoValue, setSyunkoValue] = useState('');
    const changeSyunkoDate = (value, dateString) => {
        setSyunkoDate(value)
        setSyunkoValue(dateString);
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  開始日
    const [startDate, setStartDate] = useState('')
    const [startValue, setStartValue] = useState('');
    const changeStartDate = (value, dateString) => {
        setStartDate(value)
        setStartValue(dateString);
        log(dateString)
    };
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  終了日
    const [endDate, setEndDate] = useState('')
    const [endValue, setEndValue] = useState('');
    const changeEndDate = (value, dateString) => {
        setEndDate(value)
        setEndValue(dateString);
        log(dateString)
    };



    // change bodyData
    const changeBodyData = (value, item, type) => {
        bodyList.map(tt => {
            tt.data.map(dd => {
                if (dd.result_data.id == item.id && dd.result_data.purpose_id == item.purpose_id) {
                    dd['result_data'][type] = value
                }
            })
        })
        setBodyList([...bodyList])
    }


    // 編集
    const changeUpdateFlag = () => {
        let param = {
            buken_id: state.buken_id
        }
        ApiBukenDetailExit(param).then(res => {
            if (res.status === 200) {
                setUpdateFlag(true)
            } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                log(res)
                setSuccessOpen(false)
                setRrrorOpen(true)
                setErrorContent(res.data.detail.toString())
            }
        }).catch(err => {

        })
    }


    const [images, setImages] = useState([])


    // update api
    const updateList = () => {
        document.getElementById('father_Block').scrollTop = 0;
        if (authority_id == 3 || authority_id == 4) {
            let newBodyArray = []
            bodyList.map(tt => {
                tt.data.map(dd => {
                    newBodyArray.push(dd.result_data)
                })
            })
            let errText = ''
            if (titleData.postal != "" && !formatCheck.ZipCode.test(titleData.postal)) {
                errText = errText + errorMessage.zipCodeFormatError + "\n"
            }
            if (titleData.genba_name == "") {
                errText = errText + '物件名を入力してください。\n'
            }
            if (titleData.postal == "") {
                errText = errText + '郵便番号を入力してください。\n'
            }
            if (titleData.address == "") {
                errText = errText + '所在地を入力してください。\n'
            }
            if (titleData.owner_name == "") {
                errText = errText + 'オーナーを入力してください。\n'
            }
            if (errText != '') {
                setErrorContent(errText)
                setRrrorOpen(true)
            } else {
                let params = {
                    buken_id: titleData.buken_id,
                    buken_shubetsu: titleData.buken_shubetsu,
                    papanets_buken_code: titleData.papanets_buken_code,
                    customer_buken_code: titleData.customer_buken_code,
                    area: titleData.area,
                    genba_name: titleData.genba_name,
                    postal: titleData.postal,
                    address: titleData.address,
                    owner_name: titleData.owner_name,
                    syunko_date: syunkoValue,
                    nobeyuka_area: titleData.nobeyuka_area ? titleData.nobeyuka_area : null,
                    kisu: titleData.kisu,
                    floor: titleData.floor,
                    rooms: titleData.rooms ? titleData.rooms : null,
                    tyurinjyo_num: titleData.tyurinjyo_num ? titleData.tyurinjyo_num : null,
                    tyusyajyo_num: titleData.tyusyajyo_num ? titleData.tyusyajyo_num : null,
                    remarks: titleData.remarks,
                    result: newBodyArray,
                    hokyo_buzai: titleData.hokyo_buzai,
                    renketu_buzai: titleData.renketu_buzai,
                    other_buzai: titleData.other_buzai,
                    buken_image_url: images.length > 0 ? images[0].image_key : '',
                    chenged_img_flag: images.length > 0 ? chenged_img_flag : '2'
                }
                ApiBukenUpdate(params).then(res => {
                    if (res.status == 200) {
                        setUpdateFlag(false)
                        setRrrorOpen(false)
                        setSuccessOpen(true)
                        setSuccessContent('更新が完了しました。')
                        getDetailList()
                    } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                        log(res)
                        setSuccessOpen(false)
                        setRrrorOpen(true)
                        setErrorContent(res.data.detail.toString())
                    }
                }).catch(err => {

                })
            }
        } else {
            if (startValue && endValue && (dayjs(endValue) < dayjs(startValue))) {
                setErrorContent('終了日は開始日よりも先の日付に設定してください。')
                setRrrorOpen(true)
                setSuccessOpen(false)
            } else {
                let params = {
                    buken_id: state.buken_id,
                    keiyaku_start_date: startValue ? startValue : null,
                    keiyaku_end_date: endValue ? endValue : null,
                    mansion_buken_code: mansion_buken_code == null ? 0 : mansion_buken_code,
                    img_url: images.length > 0 ? images[0].image_key : '',
                    chenged_img_flag: images.length > 0 ? chenged_img_flag : '2'
                }
                ApiBukenUpdateKeiyaku(params).then(res => {
                    if (res.status == 200) {
                        setUpdateFlag(false)
                        setRrrorOpen(false)
                        setSuccessOpen(true)
                        setSuccessContent('更新が完了しました。')
                        getDetailList()
                    } else if (res.status >= 300 && res.status < 500 && res.status != 401) {
                        log(res)
                        setSuccessOpen(false)
                        setRrrorOpen(true)
                        setErrorContent(res.data.detail.toString())
                    }
                }).catch(err => {

                })
            }

        }


    }


    // title menu content
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list
    const [titleMenuList, setTitleMenuList] = useState(titleList)
    function titleList() {
        var items = []
        if (state.buken_shubetsu === 0) {
            items = [
                { name: '物件情報', width: 130, checked: true },
                { name: '部屋情報', width: 120, checked: false },
                { name: 'メンテナンス', width: 130, checked: false },
                { name: '設備記録', width: 120, checked: false },
                { name: '営業記録', width: 120, checked: false },
                { name: 'クレーム記録', width: 130, checked: false },
                { name: '修繕記録', width: 120, checked: false },
                { name: 'ファイル', width: 122, checked: false }
            ]
        } else {
            items = [
                { name: 'コンテナマスタ', width: 142, checked: true },
                { name: 'メンテナンス', width: 142, checked: false },
                { name: '設備記録', width: 142, checked: false },
                { name: '営業記録', width: 142, checked: false },
                { name: 'クレーム記録', width: 142, checked: false },
                { name: '修繕記録', width: 142, checked: false },
                { name: 'ファイル', width: 142, checked: false }
            ]
        }

        return items
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  title list click
    const selectTitleItem = (content) => {
        if (content.name == "営業記録") {
            navigate(routerNames.PropertyDetail_business, { state: titleData })
        } else if (content.name == "部屋情報") {
            navigate(routerNames.PropertyDetail_equipment_list, { state: titleData })
        } else if (content.name == "クレーム記録") {
            navigate(routerNames.PropertyDetail_claim, { state: titleData })
        } else if (content.name == "修繕記録") {
            navigate(routerNames.PropertyDetail_repair, { state: titleData })
        } else if (content.name == "メンテナンス") {
            navigate(routerNames.PropertyDetail_calendar, { state: titleData })
        } else if (content.name == "設備記録") {
            navigate(routerNames.PropertyDetail_equipment_records, { state: titleData })
        } else if (content.name == "ファイル") {
            navigate(routerNames.PropertyDetail_file, { state: titleData })
        }
    }

    // open flag
    const changeOpenFlag = (item) => {
        log(item)
        bodyList.map(bodyItem => {
            if (bodyItem.parent_category_id === item.parent_category_id) {
                bodyItem.openFlag = !bodyItem.openFlag
            }
        })
        setBodyList([...bodyList])
    }
    // キャンセル
    const backItem = () => {
        setUpdateFlag(false)
        getDetailList()
    }



    // detailed list data
    const [falseData, setFalseData] = useState(state)
    // delete dialog
    const [deleteOpen, setDeleteOpen] = useState(false)
    const deleteOnClose = () => {
        setDeleteOpen(false)
    }
    const openDeleteDialog = () => {
        if (falseData.keiyaku_status != 'なり') {
            setDeleteOpen(true)
        } else {
            setErrorContent(errorMessage.E0037)
            setRrrorOpen(true)
        }
    }


    // 業務管理システム物件CD
    const [mansion_buken_name, setMansion_buken_name] = useState('')
    const [mansion_buken_code, setMansion_buken_code] = useState(null)



    // image update flag
    const [chenged_img_flag, setChenged_img_flag] = useState('0')

    useEffect(() => {
        if (!updateFlag) {
            setChenged_img_flag('0')
        }
    }, [updateFlag])
    useEffect(() => {
        if (updateFlag) {
            setChenged_img_flag('1')
        }
    }, [images])

    return (
        <div className={classes.ground}>
            <div className={classes.routerBlcok}><span style={{ cursor: 'pointer' }} onClick={() => { navigate(routerNames.Property) }}>物件一覧</span><ArrowForwardIos style={{ width: VW(12), height: VH(12), color: "#000000", marginLeft: VW(8), marginRight: VW(8) }}></ArrowForwardIos>物件詳細</div>
            <div className={classes.tableTitle}>
                <MenuHeader_property />
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>物件詳細</div>
            </div>
            <Common_successAlert
                open={successOpen}
                setSuccessOpen={setSuccessOpen}
                successContent={successContent}
                marginTop={VH(12)}
            />
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={VH(12)}
            />
            <div className={classes.retrievalBlock}>
                <div>物件名</div>
                <div>{titleData.genba_name}</div>
            </div>
            <div className={classes.titleMenu}>
                {titleMenuList.map((item, index) => {
                    return (
                        <div
                            className={classes.menuContent}
                            key={index}
                            style={{ width: VW(item.width), backgroundColor: item.checked ? '#E0ECFF' : '', fontWeight: item.checked ? 'bold' : '' }}
                            onClick={() => { selectTitleItem(item) }}
                        >{item.name}</div>
                    )
                })}
            </div>
            <div className={classes.updateButton}>
                {!updateFlag &&
                    <div onClick={changeUpdateFlag}>
                        <div><CreateIcon style={{ width: VW(24), height: VH(24), marginRight: VW(8) }} /></div>
                        <div>編集</div>
                    </div>
                }
                {!updateFlag && authority_id == 3 &&
                    <div style={{ marginLeft: VW(8) }} onClick={openDeleteDialog}>
                        <div><DeleteOutlineIcon style={{ color: '#E60024', width: VW(24), height: VH(24), marginRight: VW(8) }} /></div>
                        <div style={{ color: '#E60024' }}>削除</div>
                    </div>
                }
            </div>
            {
                !updateFlag ?
                    <PropertyDetail_item_First
                        titleData={titleData}
                        state={state}
                        bodyList={bodyList}
                        changeOpenFlag={changeOpenFlag}
                        images={images}
                    />
                    :
                    <PropertyDetail_input_First
                        titleData={titleData}
                        setTitleData={setTitleData}
                        state={state}
                        bodyList={bodyList}
                        changeOpenFlag={changeOpenFlag}
                        changeTitleData={changeTitleData}
                        changeBodyData={changeBodyData}
                        syunkoDate={syunkoDate}
                        changeSyunkoDate={changeSyunkoDate}
                        ownerList={ownerList}
                        getList={getOwnerList}
                        startDate={startDate}
                        changeStartDate={changeStartDate}
                        endDate={endDate}
                        changeEndDate={changeEndDate}
                        images={images}
                        setImages={setImages}
                        mansion_buken_name={mansion_buken_name}
                        setMansion_buken_name={setMansion_buken_name}
                        mansion_buken_code={mansion_buken_code}
                        setMansion_buken_code={setMansion_buken_code}
                    />
            }
            <div className={classes.bottomButton} >
                {updateFlag &&
                    <>
                        <Button onClick={backItem}>キャンセル</Button>
                        <Button onClick={updateList}>更新</Button>
                    </>
                }
            </div>
            <Property_delete
                open={deleteOpen}
                onClose={deleteOnClose}
                deleteData={[falseData]}
                historyFlag={true}
            />
        </div >
    )
}

PropertyDetail.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(PropertyDetail);